<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full p-10"
        >
          <div>
            <div class="w-full flex justify-end">
              <icon
                  :data="icons.fermer"
                  height="30"
                  width="30"
                  class="cursor-pointer"
                  original
                  @click="fermer"
              />
            </div>
          </div>

          <div class="text-center text-c18 font-c4 mt-6">
            <div
              class="pl-12 pr-12 text-c24 font-c6"
            >
              Passer le devis Nº {{ donnes.reference }} en commande ?
            </div>

          </div>

          <div v-if="error !== null" class="text-red text-c14 mt-6">{{error}}</div>
          <div class="mt-14 flex justify-center">

            <div class="w-1/2">
              <buton
                label="Confirmer"
                size="16px"
                weight="600"
                radius="5px"
                @oga="add"
              />

              <buton
                  background="#EAEAEF"
                  label="Non annuler"
                  size="16px"
                  radius="5px"
                  color="#606060"
                  weight="600"
                  @oga="fermer"
                  class="mt-6"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import success from '../../assets/icons/verre.svg'
import fermer from '../../assets/icons/fermer.svg'

export default {
  name: 'Success',
  components: { PopupBase, buton },
  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donnes: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        success,
        fermer
      },
      error: null,
      position: -1
    }
  },
  created() {
    this.wantToMakeOffer = this.activation
  },
  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

    add () {
      this.$emit('oga', true)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 45%;
    }
    .agence {
      background: #EFF3FE;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 8.03241px;
      height: 79.52px;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .inputo {
      border: 0.855148px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 4.27574px;
      font-weight: 500;
      font-size: 16px;
      line-height: 180%;
      color: #F2542D;
    }
    .activate {
      background-color: #3521B5;
      color: white;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
