<template>
  <div class="text-left">
    <div class="flex items-center">
      <div class="w-6 h-6 rond rounded-50 mr-2" v-if="position < index"/>
      <icon
          v-if="position > index && !donnes.error"
          :data="icons.valid"
          height="25"
          width="25"
          class="mr-2 cursor-pointer"
          original
      />
      <icon
          v-if="position > index && donnes.error"
          :data="icons.error"
          height="35"
          width="35"
          class="cursor-pointer"
          color="red"
          original
      />
      <div class="text-c16 font-c5 ml-1">{{ donnes.title }}</div>
    </div>

    <div
        class="mt-4 ml-3 pl-6 base"
        :class="{'bord': position > index, 'error': donnes.error }"
    >
      <div class="note p-4" v-if="donnes.note !== ''">{{ donnes.note }}</div>
      <div class="text-c10 mt-2">{{ donnes.agence }} :
        <span class="text-oho-blue">
        <span>{{ donnes.madeBy.fullname}}</span>
        </span>
      </div>
      <div class="text-c10 text-90 mt-2">
        <span>{{ new Date(donnes.created_at).toLocaleString() }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import valid from '../../../assets/icons/active.svg'
import error from '../../../assets/icons/error.svg'

export default {
  name: "Index",

  components: {
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },
    position: {
      type: Number,
      default: null
    },
    index: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      icons: {
        valid,
        error
      }
    }
  },

  created () {
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: transparent;
}
.note{
  background: #F2F5F9;
  border-radius: 5px;
  border-left: 3px solid #FBA705
}
.base{
  border-left: 1px solid #C4C4C4;
}
.bord{
  border-left: 1px solid #2B9E7C;
}
.rond {
  border: 3px solid #C4C4C4;
}
.error {
  border-left: 1px solid red;
}
</style>
